import React, { useContext, useState, useEffect } from "react";
import { Button, Input, Table } from "antd";
import "./CreditoRemoto.css";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as links from "../../../../utils/links";
import LoadingAction from "../../../../themes/LoadingAction/LoadingAction";
import moment from "moment";

const CreditoRemoto = (props) => {
  const { setDataUser, authInfo, setNotiMessage } = useContext(AuthContext);
  const { dataUser } = authInfo;
  const location = useLocation();
  const navigate = useNavigate();
  const token = authInfo?.dataUser?.token;

  const { id } = useParams();

  const [data, setData] = useState({
    valor: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [listHistory, setListHistory] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getData(id);
  }, []);

  const handleChange = (name, value) => {
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => {
      let errorsTemp = { ...prev };
      delete errorsTemp[name];
      return errorsTemp;
    });
  };

  const onSave = () => {
    let errorsTemp = {};
    if (data.valor === "") {
      errorsTemp.valor = "Este campo é obrigatório";
    }

    if (Object.keys(errorsTemp).length > 0) {
      setErrors(errorsTemp);
      return;
    }

    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVIDOR}/credito-remoto`,
        { id, valor: data.valor },
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setNotiMessage({
          type: "success",
          message: res?.data?.retorno,
        });
        getData(id);
      })
      .catch((err) => {
        setIsLoading(false);

        setNotiMessage({
          type: "error",
          message: err.response?.data?.msg
            ? err.response?.data?.msg
            : `A sua sessão expirou, para continuar faça login novamente.`,
        });
      });
  };

  const getData = (id) => {
    if (id.trim() !== "") {
      setLoadingTable(true);
      axios
        .get(`${process.env.REACT_APP_SERVIDOR}/creditos-remotos-adm?idMaquina=${id}`, {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          setLoadingTable(false);
          if (res.status === 200 && Array.isArray(res.data)) {
            setListHistory(res.data);
          }
        })
        .catch((err) => {
          setLoadingTable(false);
          if ([401, 403].includes(err.response.status)) {
            // setNotiMessage('A sua sessão expirou, para continuar faça login novamente.');
            setNotiMessage({
              type: "error",
              message:
                "A sua sessão expirou, para continuar faça login novamente.",
            });
            if(dataUser.key === 'ADMIN'){
              navigate(links.ADMIN_SIGNIN)
              setDataUser(null);
            }else{
              navigate(links.SIGNIN)
              setDataUser(null);
            }
          }
        });
    }
  };

  const columns = [
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      render: (valor) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(valor),
    },
    {
      title: "Data Hora",
      dataIndex: "dataHora",
      key: "dataHora",
      className: 'column-nome',
      render: (dataHora) => (
        <span>{moment(dataHora).format("DD/MM/YYYY HH:mm:ss")}</span>
      ),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      className: 'column-nome',
      render: (email) => (
        <span>{email}</span>
      ),
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
      className: 'column-nome',
      render: (ip) => (
        <span>{ip}</span>
      ),
    },
    {
      title: "Id Máquina",
      dataIndex: "idMaquina",
      key: "idMaquina",
      className: 'column-nome',
      render: (idMaquina) => (
        <span>{idMaquina}</span>
      ),
    },
  ]

  return (
    <>
      {isLoading && <LoadingAction />}
      <div>
        <div className="Trocar_header">
          <div className="Trocar_header_title">
            Adicionando $ na maquina teste
          </div>
          <Button
            className="Trocar_header_back"
            onClick={() => {
              navigate(`${links.CLIENTES_MAQUINAS_FORNECEDOR_SEARCH}/${id}`, {
                state: location.state,
              });
            }}
          >
            <span>VOLTAR</span>
          </Button>
        </div>

        <div className="Trocar_content">
          <div className="Trocar_itemField">
            <label className="Trocar_itemFieldLabel" htmlFor="valor">
              Valor:
            </label>
            <Input
              placeholder={""}
              value={data.valor}
              id="valor"
              type="text"
              name="valor"
              autoComplete="valor"
              onChange={(event) => {
                handleChange("valor", event.target.value);
              }}
              className={`${!!errors.valor ? "Trocar_inputError" : ""}`}
            />
            {errors.valor && (
              <div className="Trocar_itemFieldError">{errors.valor}</div>
            )}
          </div>

          <Button
            className="Trocar_saveBtn"
            onClick={() => {
              if (!isLoading) onSave();
            }}
            disabled={isLoading}
          >
            ENVIAR
          </Button>
        </div>
        <div style={{padding: '20px'}}>
          <h2 style={{ textAlign: 'center', margin: '5px', fontSize: '1rem',fontWeight: '500' }}>{`Histórico`}</h2>
          <Table
            className="custom-table"
            columns={columns}
            dataSource={listHistory}
            pagination={false}
            loading={loadingTable}
            scroll={{ x: 'max-content' }}
            locale={{
              emptyText:
                searchText.trim() !== "" ? (
                  "-"
                ) : (
                  <div>Não foram encontrados resultados para sua pesquisa.</div>
                ),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CreditoRemoto;
