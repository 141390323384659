import React, { useContext, useEffect, useState } from "react";
import LoadingAction from "../../../../themes/LoadingAction/LoadingAction";
import "./PagamentosCliente.css";
import { Button, Table, Select } from "antd";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import * as links from "../../../../utils/links";

const PagamentosCliente = (props) => {

  const { Option } = Select;

  const { setDataUser, authInfo, setNotiMessage } = useContext(AuthContext);
  const { dataUser } = authInfo;

  let navigate = useNavigate();
  const token = authInfo?.dataUser?.token;

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [listCanals, setListCanals] = useState([]);
  const [total, setTotal] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const [filtro, setFiltro] = useState("todos");
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);

  const { RangePicker } = DatePicker;
  useEffect(() => {
    if(filtro === "periodo"){
      setNotiMessage({
        type: "info",
        message:
          "Selecione o período de datas para busca.",
      });
    }else{
      getData(filtro);
    }
  }, [filtro]);

  useEffect(() => {
    if (dataFim != null) {
      getData(filtro);
    }
  }, [dataFim]);

  const getData = (filtro) => {
    let url = `${process.env.REACT_APP_SERVIDOR}/payments-client?filtro=${filtro.toLowerCase()}`;

    if (dataInicio && filtro === 'periodo') {
      url += `&dataInicio=${dataInicio}T03:00:00.000Z`;
    }

    if (dataFim && filtro === 'periodo') {
      const dataFimAjustada = new Date(dataFim);
      dataFimAjustada.setDate(dataFimAjustada.getDate() + 1);
      const dataFimISO = dataFimAjustada.toISOString().split("T")[0];
      url += `&dataFim=${dataFimISO}T03:00:00.000Z`;
    }

    setLoadingTable(true);
    axios
      .get(
        url,
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setLoadingTable(false);
        setTotal(res.data.soma);
        if (res.status === 200 && Array.isArray(res.data.pagamentosRecentes)) {
          setListCanals(res.data.pagamentosRecentes);
        }
      })
      .catch((err) => {
        setLoadingTable(false);
        if ([401, 403].includes(err.response.status)) {
          setNotiMessage({
            type: "error",
            message:
              "A sua sessão expirou, para continuar faça login novamente.",
          });
          if (dataUser.key === "ADMIN") {
            navigate(links.ADMIN_SIGNIN);
            setDataUser(null);
          } else {
            navigate(links.SIGNIN);
            setDataUser(null);
          }
        }
      });
  };

  const columns = [
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
      width: 400,
      render: (data) => (
        <span>{moment(data).format("DD/MM/YYYY HH:mm:ss")}</span>
      ),
    },
    {
      title: "Forma de pagamento",
      dataIndex: "tipo",
      key: "tipo",
      render: (tipo, record) => (
        <span>
          {tipo === "bank_transfer"
            ? "PIX"
            : tipo === "CASH"
            ? "Especie"
            : tipo === "debit_card"
            ? "Débito"
            : tipo === "credit_card"
            ? "Crédito"
            : tipo === "1"
            ? "Crédito"
            : tipo === "11"
            ? "PIX"
            : tipo === "8"
            ? "Débito"
            : ""}
        </span>
      ),
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      render: (valor) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(valor),
    },
    {
        title: "Taxas",
        dataIndex: "taxas",
        key: "taxas",
        render: (taxas) =>
            new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            }).format(taxas),
    },
    {
        title: "Máquina",
        dataIndex: "maquinaNome",
        key: "maquinaNome",
    },
    {
        title: "Máquina Descrição",
        dataIndex: "maquinaDescricao",
        key: "maquinaDescricao",
    },
    {
        title: "Operadora",
        dataIndex: "operadora",
        key: "operadora",
    },
    {
      title: "Identificador",
      dataIndex: "mercadoPagoId",
      key: "mercadoPagoId",
    },
  ];

  return (
    <div className="Admin_PagamentosSearch_container">
      {isLoading && <LoadingAction />}
      <div className="Admin_PagamentosSearch_header">
        <div className="Admin_PagamentosSearch_header_left">
          <div className="Admin_Dashboard_staBlockTitle">
            Pagamentos Recentes
          </div>
          <div className="Admin_PagamentosSearch_datePicker">
            <Select
              defaultValue="Todos"
              style={{ border: "1px solid", borderRadius: "4px", width: 288}}
              onChange={(value) => setFiltro(value)}
            >
              <Option value="todos">Todos</Option>
              <Option value="periodo">Período</Option>
              <Option value="ultimos7dias">Últimos 7 dias</Option>
              <Option value="mesatual">Mês Atual</Option>
              <Option value="mespassado">Mês Passado</Option>
            </Select>
          </div>
          <div className="Admin_PagamentosSearch_datePicker">
            <RangePicker
              disabled={filtro !== "periodo"}
              style={{ border: "1px solid", borderRadius: "4px" }}
              placeholder={["Data Inicial", "Data Final"]}
              onChange={(dates, dateStrings) => {
                setDataInicio(dateStrings ? dateStrings[0] : null);
                setDataFim(dateStrings ? dateStrings[1] : null);
              }}
            />
          </div>
          <Button
            className="Admin_PagamentosSearch_header_back"
            onClick={() =>
              navigate(`${links.DASHBOARD_FORNECEDOR}`)
            }
          >
            VOLTAR
          </Button>
        </div>
      </div>
      <div className="Admin_PagamentosSearch_body">
        <div className="Admin_PagamentosSearch_content">
          <div
            className="Admin_PagamentosSearch_titleList_main"
            style={{ marginBottom: "10px" }}
          >
            <div className="Admin_PagamentosSearch_titleList">
              <div style={{ marginLeft: "20px" }}>Total</div>
              <div className="Admin_PagamentosSearch_nbList">
                {Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(total)}
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={listCanals}
            pagination={false}
            loading={loadingTable}
            locale={{
              emptyText:
                searchText?.trim() !== "" ? (
                  "-"
                ) : (
                  <div>Não foram encontrados resultados para sua pesquisa.</div>
                ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PagamentosCliente;
