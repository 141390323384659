import React, { useState, useContext } from "react";
import { Button, Input } from "antd";
import { AuthContext } from "../../../../contexts/AuthContext";
import LoadingAction from "../../../../themes/LoadingAction/LoadingAction";
import { useNavigate } from "react-router-dom";
import * as links from "../../../../utils/links";
import "./ResetSenha.css";
import axios from "axios";

const ResetSenha = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const { authInfo, setNotiMessage, setDataUser} = useContext(AuthContext);
  let navigate = useNavigate();

  const token = authInfo?.dataUser?.token;

  const validatePassword = (value) => {
    if (value.length < 6 && value !== "") {
      return "A senha precisa ter no mínimo 6 caracteres.";
    }
    return "";
  };

  const validateConfirmPassword = (value) => {
    if (value !== password && value !== "") {
      return "As senhas não coincidem.";
    }
    return "";
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setErrors((prev) => ({
      ...prev,
      password: validatePassword(value),
    }));
  };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    setErrors((prev) => ({
      ...prev,
      confirmPassword: validateConfirmPassword(value),
    }));
  };

  const onNewPassword = () => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVIDOR}/cliente-trocar-senha-himself`,
        { newPassword: confirmPassword },
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        setNotiMessage({
          type: "success",
          message: "Senha redefinida com sucesso!",
        });
        navigate(links.SIGNIN)
        setDataUser(null);
      })
      .catch((err) => {
        setIsLoading(false);
        setNotiMessage({
          type: "error",
          message: err.response?.data?.error,
        });
      });
  };

  const onSave = () => {
    if (!errors.password && !errors.confirmPassword && password && confirmPassword) {
      onNewPassword();
      console.log("Senha salva com sucesso!", { password });
    } else {
      console.log("Preencha os campos corretamente antes de salvar.");
    }
  };

  return (
    <div className="ResetSenha_container">
      {isLoading && <LoadingAction />}
      <h2>Redefinir Senha</h2>

      <div className="ResetSenha_field">
        <label htmlFor="password">Escolha uma Nova Senha:</label>
        <Input.Password
          id="password"
          value={password}
          onChange={(e) => handlePasswordChange(e.target.value)}
          placeholder="Digite sua nova senha"
          status={errors.password ? "error" : ""}
        />
        {errors.password && (
          <div className="ResetSenha_errorMessage">{errors.password}</div>
        )}
      </div>

      <div className="ResetSenha_field">
        <label htmlFor="confirmPassword">Repita sua Nova Senha:</label>
        <Input.Password
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => handleConfirmPasswordChange(e.target.value)}
          placeholder="Confirme sua nova senha"
          status={errors.confirmPassword ? "error" : ""}
        />
        {errors.confirmPassword && (
          <div className="ResetSenha_errorMessage">{errors.confirmPassword}</div>
        )}
      </div>

      <Button type="primary" className="Edit_Cliente_saveBtn" onClick={onSave} disabled={errors.password || errors.confirmPassword || !password}>
        Trocar Minha Senha
      </Button>
    </div>
  );
};

export default ResetSenha;
