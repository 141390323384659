import React from 'react';
import { DatePicker } from 'antd';
import './CustomRangePicker.css';  // Importando o CSS

const { RangePicker } = DatePicker;

const CustomRangePicker = ({ setDataInicio, setDataFim, resetSearch, id }) => {
  return (
    <div className="d-flex col-7 col-md-auto">
      <RangePicker
        style={{
          width: '100%', // Faz o RangePicker ocupar 100% da largura
        }}
        placeholder={["Data Inicial", "Data Final"]}
        onChange={(dates, dateStrings) => {
          if(!dates){
            resetSearch(id);
          }
          setDataInicio(dateStrings ? dateStrings[0] : null);
          setDataFim(dateStrings ? dateStrings[1] : null);
        }}
      />
    </div>
  );
};

export default CustomRangePicker;
